.header_container {
  height: 120px;
}

.header {
  background-color: #fdb814;
  height: 50px;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250%;
  margin-left: 10%;
  background-size: contain;
  background-repeat: no-repeat;
}

.content_container {
  margin: auto;
  max-width: 1440px;
  margin-top: 20px;
}

.application {
  background-color: #f6f6f6;
  min-height: 960px;
}

@media print {
  .header_container {
    display: none;
  }
}

@media print {
  .application {
    background-color: white;
  }
}
