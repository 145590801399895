.container {
    display: block;
    height: 100%;
    width: 100%;
    max-width: 1920px;
    margin: auto;
}

.login {
    width: 350px;
    margin: auto;
}