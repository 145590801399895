.profile_container {
  height: 100%;
}

/* .profile_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.profile_content {
  text-align: center;
}
